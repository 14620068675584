<template>
  <div class="webview-container">
    <iframe  :src="websrc" frameborder="0" style="width:100%;height:100%;" allow="fullscreen" id="iframeWeb"></iframe>
  </div>
</template>

<script>
import { getShareCourseOfPreview , putShareCourseOfPreview} from '@/libs/api/share'
import { mapState } from 'vuex';
export default {
  data() {
    return {
      websrc: "",
    };
  },
  computed: {
    ...mapState({
      is_web: state => state.app.isWebSide,
    }),
  },
  created() {
    this.websrc = this.$route.params.websrc || "";
    
    if(!this.websrc) {
      this.getdetail();
    }
  },
  methods: {
    getdetail() {
      let { teloremail, p } = this.$route.query;
      getShareCourseOfPreview(
        {p}
      ).then(res => {
        if(res.errorcode == 0) {
          let { open_way, course_url, p } = res.data;
          if(open_way === 'open') {
            this.websrc = course_url;
          }
        }else {
          let obj = {
            1:"error",
            2:"inexistence",
            3: "expire",
            4: "upperlimit"
          };
          // this.$router.push( `/invalid/${obj[res.errorcode]}` );
          this.$router.push({
            name: 'Invalid',
            params: {
              type: obj[res.errorcode],
              msg: res.msg
            }
          });
        }
      })
    },
    closeCurrentPage() {
      if (navigator.userAgent.indexOf('MSIE') > 0) { // close IE
        if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
            window.opener = null;
            window.close();
        } else {
            window.open('', '_top');
            window.top.close();
        }

      } else { // close chrome;It is effective when it is only one.
        window.opener = null;
        window.open('', '_self');
        window.close();
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .webview-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .loading-img {
    width: 140px;
    height: 140px;
    object-fit: contain;
  }
</style>